import { Controller } from "@hotwired/stimulus";
import { HtmxRequestConfig } from "htmx.org";

export default class FormController extends Controller {
  static values = { warnOnUnsavedUnload: Boolean };
  declare readonly warnOnUnsavedUnloadValue: boolean;

  connect(): void {
    if (this.warnOnUnsavedUnloadValue) {
      document.body.addEventListener("htmx:beforeRequest", this.htmxCallback);
      window.onbeforeunload = () => {
        const isFormDirty = this.isFormDirty();
        console.log("onbeforeunload", isFormDirty);
        return isFormDirty ? true : null;
      };
    }
  }

  disconnect(): void {
    if (this.warnOnUnsavedUnloadValue) {
      document.body.removeEventListener(
        "htmx:beforeRequest",
        this.htmxCallback,
      );
      window.onbeforeunload = null;
    }
  }

  submit(event: Event): void {
    event.preventDefault();
    const form = this.element as HTMLFormElement;

    const fileInputs = Array.from(
      form.querySelectorAll<HTMLInputElement>(
        'input[type="file"][data-auto-open-when-missing-on-submit="true"]',
      ),
    );
    for (const fileInput of fileInputs) {
      if ((fileInput.files?.length ?? 0) === 0) {
        fileInput.click();
        fileInput.addEventListener("change", () => {
          if ((fileInput.files?.length ?? 0) > 0) {
            this.submit(event);
          }
        });
        return;
      }
    }
    form.requestSubmit();
  }

  isFormDirty() {
    const form = this.element as HTMLFormElement;
    const inputs = Array.from(
      form.querySelectorAll<HTMLInputElement>("input[data-initial-value]"),
    );

    for (const input of inputs) {
      const value = input.value;
      const initialValue = input.getAttribute("data-initial-value");

      if (value !== initialValue) {
        return true;
      }
    }

    const checkboxes = Array.from(
      form.querySelectorAll<HTMLInputElement>("input[data-initial-checked]"),
    );

    for (const input of checkboxes) {
      const value = input.checked;
      const initialValue = JSON.parse(
        input.getAttribute("data-initial-checked") ?? "false",
      );

      if (value !== initialValue) {
        return true;
      }
    }

    const selects = Array.from(
      form.querySelectorAll<HTMLInputElement>("select[data-initial-value]"),
    );

    for (const select of selects) {
      const value = select.value;
      const initialValue = select.getAttribute("data-initial-value");

      if (value !== initialValue) {
        return true;
      }
    }

    return false;
  }

  private htmxCallback = (event: Event) => {
    console.log(this.isHtmxRequestEvent(event), event);
    if (
      this.isHtmxRequestEvent(event) &&
      event.detail.requestConfig.verb !== "get"
    ) {
      // allow submits of forms
      return;
    }
    if (!this.isFormDirty()) {
      return;
    }
    if (!confirm("You have unsaved changes. Are you sure you want to leave?")) {
      event.preventDefault(); // Cancel the request
    }
  };

  private isHtmxRequestEvent(
    event: Event,
  ): event is Event & { detail: { requestConfig: HtmxRequestConfig } } {
    // @ts-expect-error typescript thinks this does not exist
    return !!event.detail.requestConfig;
  }
}
