// Required when not using "index.html" as Vite's entry point.
import "vite/modulepreload-polyfill";

// Tell Vite about our CSS entry point.
import "./css/main.css";

// Web components
import "@github/relative-time-element";
import "@justinribeiro/lite-youtube";
import "@shoelace-style/shoelace/dist/components/copy-button/copy-button.js";

// Third-party libraries
import htmx from "htmx.org/dist/htmx.esm.js";

// Our modules and adapters around third-party libraries
import * as autocomplete from "./autocomplete";
import * as chart from "./chart";
import * as dropzone from "./dropzone";
import * as googleTagManager from "./google-tag-manager";
import * as intercom from "./intercom";
import "./icons";
import * as pdf from "./pdf";
import * as plausible from "./plausible";
import * as posthog from "./posthog";
import "./sentry";
import "./shoelace";
import * as subConfigure from "./sub-configure";
import * as tagify from "./tagify";
import * as utm from "./utm";

// Our Stimulus controllers
import "./app";

/** Helper for running code when the DOM is ready. */
const onDomReady = (callback: () => void): void => {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", callback);
  } else {
    callback();
  }
};

onDomReady(() => {
  // Called once on full page reloads.
  console.debug("[hub]", new Date().getTime(), "onDomReady");

  intercom.init();
  plausible.init();
  posthog.init();
  utm.init();
});

htmx.onLoad((node: Node) => {
  if (node.nodeType !== Node.ELEMENT_NODE) {
    return;
  }
  const element = node as Element;

  // Called on the initial page load and all partial loads by htmx.
  console.debug("[hub]", new Date().getTime(), "htmx:onLoad", {
    tag: element.tagName,
    id: element.id,
    class: element.classList.toString(),
  });

  // Run our initializers on the new content.
  autocomplete.initTree(element);
  chart.initTree(element);
  dropzone.initTree(element);
  googleTagManager.initTree(element);
  pdf.initTree(element);
  plausible.initTree(element);
  subConfigure.initTree(element);
  tagify.initTree(element);

  if (element.id === "body-content") {
    // Full page load boosted by htmx.
    intercom.trackPageView();
  }
});
