import * as fontawesome from "@fortawesome/fontawesome-svg-core";
import { registerIconLibrary } from "@shoelace-style/shoelace";

// Register the Font Awesome icons with Shoelace so they can be used via the
// sl-icon component.
//
// We use the library name "default" to replace Shoelace's default icon library
// and make the markup needed to use our icons shorter.
registerIconLibrary("default", {
  resolver: (name: string): string => {
    const dashIndex = name.indexOf("-");
    const icon = fontawesome.icon({
      prefix: name.slice(0, dashIndex) as fontawesome.IconPrefix,
      iconName: name.slice(dashIndex + 1) as fontawesome.IconName,
    });
    const svg = icon.html.join("");
    return `data:image/svg+xml,${encodeURIComponent(svg)}`;
  },
  mutator: (svg: SVGElement): void => {
    svg.setAttribute("fill", "currentColor");
  },
});
