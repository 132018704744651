import { Controller } from "@hotwired/stimulus";

export default class DecisionMakersController extends Controller {
  static targets = ["formset"];
  declare readonly formsetTarget: HTMLElement;

  toggleDecisionMaker(event: InputEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const button = event.target as HTMLButtonElement | undefined;
    const form = button?.closest(".formset-form-instance");
    if (!form) {
      return;
    }
    const wrapper = form.querySelector<HTMLElement>(".formset-form-wrapper");
    const id = form.querySelector<HTMLInputElement>('input[name*="id"]')?.value;
    const isSelectedField = form.querySelector<HTMLInputElement>(
      'input[name*="is_selected"]',
    );
    if (!button || !wrapper || !isSelectedField) {
      return;
    }
    if (id) {
      // this is the forms for an instance
      if (wrapper.classList.contains("hidden")) {
        isSelectedField.value = "true";
        button.textContent = "Unselect";
        button.classList.remove("c-btn-green-tint");
        button.classList.add("c-btn-orange-tint");
        wrapper.classList.remove("hidden");
      } else {
        isSelectedField.value = "false";
        button.textContent = "Select";
        wrapper.classList.add("hidden");
        button.classList.add("c-btn-green-tint");
        button.classList.remove("c-btn-orange-tint");
      }
    } else {
      // this is the extra forms
      form.classList.add("hidden");
      form.querySelectorAll<HTMLInputElement>("input").forEach((input) => {
        input.value = "";
      });
      // set isSelectedField to default value since we don't want to save it
      isSelectedField.value = "true";
      const deleteCheckBox = form.querySelector<HTMLInputElement>(
        'input[name$="DELETE"]',
      );
      if (deleteCheckBox === null) return;
      deleteCheckBox.checked = true;

      this.updateName(event);
    }
  }

  updateName(event: InputEvent): void {
    const form = (event.target as HTMLElement)?.closest(
      ".formset-form-instance",
    );
    if (!form) return;

    const h2 = form.querySelector<HTMLElement>("h2");
    if (h2) {
      const firstName = form.querySelector<HTMLInputElement>(
        'input[name*="first_name"]',
      );
      const lastName = form.querySelector<HTMLInputElement>(
        'input[name*="last_name"]',
      );
      h2.textContent = `${firstName?.value} ${lastName?.value}`;
    }
  }

  addDecisionMaker(event: Event | null): void {
    /* Un-hides the next decision maker form.

    All forms in the formset are hidden by default. When the user clicks the "Add" button,
    the next form is un-hidden.
    */

    if (event !== null) event.preventDefault();

    const nextHiddenForm = this.formsetTarget.querySelector(
      ".hidden.formset-form-instance",
    );
    if (nextHiddenForm === null) return;

    nextHiddenForm.classList.remove("hidden");

    const isSelectedField = nextHiddenForm.querySelector<HTMLInputElement>(
      'input[name*="is_selected"]',
    );
    if (isSelectedField === null) return;

    isSelectedField.value = "true";

    // Un-check the "DELETE" checkbox, if it exists.
    const deleteCheckBox: HTMLInputElement | null =
      nextHiddenForm.querySelector('input[name$="DELETE"]');

    if (deleteCheckBox === null) return;
    deleteCheckBox.checked = false;
  }

  removeDecisionMaker(event: Event): void {
    /* Hide a decision maker form in the formset.

    When the user clicks the "Remove" button, the form is hidden and the "DELETE" checkbox
    is checked.
     */

    event.preventDefault();

    if (event.target === null) return;
    const form = (event.target as HTMLElement).closest(
      ".formset-form-instance",
    );

    if (form === null) return;

    const isSelectedField = form.querySelector<HTMLInputElement>(
      'input[name*="is_selected"]',
    );
    if (isSelectedField === null) return;

    isSelectedField.value = "false";
    form.classList.add("hidden");

    // Check the "DELETE" checkbox so that the decision maker is deleted on the next submit.
    const deleteCheckBox: HTMLInputElement | null = form.querySelector(
      'input[name$="DELETE"]',
    );
    if (deleteCheckBox === null) return;
    deleteCheckBox.checked = true;
  }
}
