import { Controller } from "@hotwired/stimulus";

export default class ModalToggleController extends Controller {
  static values = { id: String };
  declare readonly idValue: string;

  trigger(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.dispatch("open", { detail: { modalId: this.idValue } });
  }
}
